import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Make Up Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Open Workout 20.3 will be released tonight at 8:00pm.  It will be
Friday’s class workout.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our Free Halloween workout at 9:00 & 10:00am.
 Come dressed IN COSTUME! Best costume wins a prize!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We are in need for Judges/Volunteers for our Granite Games Winter
Throwdown on December 7th.  If you aren’t competing please consider
helping out!  We’ll get you a shirt (if you volunteer by Nov 3rd), and
feed you throughout the day.  Sign up on competition corner.net and be
sure to put in your shirt size.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      